import Vue from "vue";
import VueRouter from "vue-router";
import VueMeta from "vue-meta";
import store from "./store";

import Landing from "./views/Landing.vue";

Vue.use(VueRouter);
Vue.use(VueMeta);

//for (auth-enabling) routes that should redirect if already authenticated
function toHomeIfAlreadyAuth(to, from, next) {
  const auth = store.getters.auth;
  if (auth) {
    next("/dashboard");
  } else {
    next();
  }
}

//for routes that require authentication
function toLoginIfNotAuth(to, from, next) {
  const auth = store.getters.auth;
  if (!auth) {
    next({
      path: "/login",
      query: { nextUrl: to.fullPath },
    });
  } else {
    next();
  }
}

const routes = [
  // Public Routes
  {
    path: "/",
    name: "Landing",
    component: Landing,
  },
  {
    path: "/about-us",
    name: "AboutUs",
    component: () => import("./views/public/AboutUs.vue"),
  },
  {
    path: "/free-pdfs",
    name: "FreePdfs",
    component: () => import("./views/public/Pdf/Pdf.vue"),
  },
  {
    path: "/free-pdfs/:id",
    name: "FreePdfGroup",
    component: () => import("./views/public/Pdf/PdfGroup.vue"),
  },
  {
    path: "/free-pdfs/:groupID/folder/:folderID",
    name: "FreePdfFolder",
    component: () => import("./views/public/Pdf/PdfFolder.vue"),
  },
  {
    path: "/live-quiz",
    name: "LiveQuiz",
    component: () => import("./views/public/LiveQuiz.vue"),
  },
  {
    path: "/course/:id",
    name: "Course",
    component: () => import("./views/public/Course.vue"),
  },
  {
    path: "/course-tests/:id",
    name: "CourseTests",
    component: () => import("./views/public/AllCourseTests.vue"),
  },
  {
    path: "/course-tests/:courseID/testGroup/:testGroupID",
    name: "TestGroupTests",
    component: () => import("./views/public/TestGroupTests.vue"),
  },

  // Auth Routes
  {
    path: "/login",
    name: "Login",
    component: () => import("./views/auth/Login.vue"),
    beforeEnter: (to, from, next) => {
      toHomeIfAlreadyAuth(to, from, next);
    },
  },

  {
    path: "/register",
    name: "Register",
    component: () => import("./views/auth/Register.vue"),
    beforeEnter: (to, from, next) => {
      toHomeIfAlreadyAuth(to, from, next);
    },
  },

  // User Routes
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("./views/home/Dashboard.vue"),
    beforeEnter: (to, from, next) => {
      toLoginIfNotAuth(to, from, next);
    },
  },

  {
    path: "/profile",
    name: "Profile",
    component: () => import("./views/home/Profile.vue"),
    beforeEnter: (to, from, next) => {
      toLoginIfNotAuth(to, from, next);
    },
  },

  {
    path: "/test/:id",
    name: "Test",
    props: true,
    component: () => import("./views/home/Test.vue"),
    beforeEnter: (to, from, next) => {
      toLoginIfNotAuth(to, from, next);
    },
  },

  {
    path: "/result/:id",
    name: "Result",
    props: true,
    component: () => import("./views/home/Result.vue"),
    beforeEnter: (to, from, next) => {
      toLoginIfNotAuth(to, from, next);
    },
  },

  {
    path: "/live-tests",
    name: "LiveTests",
    props: true,
    component: () => import("./views/home/LiveTests.vue"),
    beforeEnter: (to, from, next) => {
      toLoginIfNotAuth(to, from, next);
    },
  },

  //Misc
  {
    path: "/premium-pass/:id",
    name: "PremiumPassDialog",
    component: () => import("./views/home/Subscribe.vue"),
    beforeEnter: (to, from, next) => {
      toLoginIfNotAuth(to, from, next);
    },
  },

  {
    path: "/premium-pass",
    name: "PremiumPass",
    component: () => import("./views/home/Subscribe.vue"),
  },

  {
    path: "/terms-and-conditions",
    name: "TnC",
    component: () => import("./views/misc/TnC.vue"),
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: () => import("./views/misc/PrivacyPolicy.vue"),
  },

  // 404
  {
    path: "*",
    name: "NotFound404",
    component: () => import("./views/NotFound404.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  next();
});

export default router;
