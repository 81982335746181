<template>
  <div id="testimonials">
    <v-container>
      <!-- Everything you need... -->
      <v-row>
        <v-col cols="12" sm="12" class="mb-9 mb-md-0">
          <h1 class="text-h4 font-weight-light text-center">
            Everything you need for your exam preparation :
          </h1>
        </v-col>
      </v-row>

      <!-- Icons -->
      <v-row class="mt-12 mb-9 text-center" justify="center">
        <v-col class="subtitle-1" cols="12" sm="2" md="3">
          <v-icon size="90" color="blue darken-2">mdi-bookshelf</v-icon>
          <div class="text-overline mt-2">Online Courses</div>
        </v-col>

        <v-col class="subtitle-1" cols="12" sm="2" md="3">
          <v-icon size="90" color="orange darken-3"
            >mdi-file-document-edit</v-icon
          >
          <div class="text-overline mt-2">Practice Papers</div>
        </v-col>

        <v-col class="subtitle-1" cols="12" sm="2" md="3">
          <v-icon size="90" color="teal darken-2">mdi-timetable</v-icon>
          <div class="text-overline mt-2">Test Series</div>
        </v-col>

        <v-col class="subtitle-1" cols="12" sm="2" md="3">
          <v-icon size="90" color="pink darken-1">mdi-hand-heart</v-icon>
          <div class="text-overline mt-2">Complete Guidance</div>
        </v-col>
      </v-row>

      <!-- Testimonials headline -->
      <v-row>
        <v-col cols="12" sm="12" class="mt-16 mb-9 mb-md-n12">
          <h1 class="text-h4 font-weight-light text-center">
            Hear it from our students :
          </h1>
        </v-col>
      </v-row>
      <v-row class="mt-n5">
        <v-col cols="12" sm="12">
          <!-- Testimonial carousel Medium and Up -->
          <v-carousel
            v-if="!$vuetify.breakpoint.mobile"
            hide-delimiters
            cycle
            :show-arrows="false"
            class="hidden-sm-and-down"
          >
            <v-carousel-item v-for="(slide, i) in slideData" :key="i">
              <v-row
                class="fill-height black--text"
                align="center"
                justify="space-around"
                no-gutters
              >
                <v-col
                  v-for="(testimonial, j) in slide"
                  :key="j"
                  cols="12"
                  sm="12"
                  md="3"
                >
                  <v-card
                    light
                    color="grey lighten-3"
                    class="ma-1 pa-md-4 px-md-6 rounded-xl d-flex align-start"
                    height="250"
                    outlined
                  >
                    <div>
                      <span class="text-right text-h5 d-flex align-center">
                        <v-icon class="mr-2" color="blue darken-4"
                          >mdi-account-circle mdi-48px</v-icon
                        >
                        <span class="text-left">
                          {{ testimonial.name }}
                        </span>
                      </span>
                      <v-divider class="my-3"></v-divider>
                      <span class="pa-1 pa-md-2">
                        "{{ testimonial.text }}"
                      </span>
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </v-carousel-item>
          </v-carousel>

          <!-- Testimonial carousel Small and Down -->
          <v-carousel
            hide-delimiters
            show-arrows-on-hover
            height="250"
            class="hidden-lg-and-up"
            cycle
            v-else
          >
            <div v-for="(slide, i) in slideData" :key="i">
              <v-carousel-item v-for="(testimonial, j) in slide" :key="j">
                <v-row
                  class="fill-height black--text mt-4"
                  align="start"
                  justify="center"
                  no-gutters
                >
                  <v-col cols="12" sm="12">
                    <v-card
                      light
                      color="grey lighten-2"
                      class="ma-1 mx-md-2 pa-3 pa-md-4 d-flex align-start"
                      outlined
                    >
                      <div>
                        <span class="text-right text-h5 d-flex align-start">
                          <v-icon class="mr-2" color="blue darken-4">
                            mdi-account-circle mdi-36px
                          </v-icon>
                          <span class="text-left">
                            {{ testimonial.name }}
                          </span>
                        </span>
                        <v-divider class="my-3"></v-divider>
                        <span class="text-right pa-1 pa-md-2">
                          "{{ testimonial.text }}"
                        </span>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </v-carousel-item>
            </div>
          </v-carousel>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Testimonials",
  data: () => ({
    slideData: [
      [
        {
          name: "Keshav Vijaypal",
          text: "Test series is really good and as per the exam standard, Test interface is as it is as in real exam. Helped me a lot in cracking DSSSB PGT Exam...",
        },
        {
          name: "Vinay Yadav",
          text: "This website is lit and really working hard for students.. very very thanks to all teachers for this...",
        },
        {
          name: "Vineeta Kaushik",
          text: "Test was good. Thanks a lot for bringing such Tests...",
        },
      ],
    ],
  }),
};
</script>

<style>
</style>