<template>
  <div id="courses">
    <v-container>
      <v-row>
        <v-card class="w-100 rounded-lg pa-6" :disabled="loading">
          <!-- Courses headline -->
          <v-card-title class="montserrat-font">
            <h1
              v-if="showAllCourses || !landingPage"
              :class="landingPage ? 'text-h5' : 'text-subtitle-2'"
            >
              All Tests Series :
            </h1>

            <h1 v-else :class="landingPage ? 'text-h5' : 'text-subtitle-2'">
              Popular Tests Series :
            </h1>
          </v-card-title>

          <!-- if no fetching error -->
          <v-card-text class="mt-5" v-if="!error">
            <!-- if courses fetched -->
            <v-row justify="start" v-if="!loading && courses.length > 0">
              <v-col md="2" v-for="(course, i) in topCourses" :key="i">
                <router-link
                  :to="{
                    path: `/course/${allCoursesIndex(course.courseName)}`,
                  }"
                >
                  <div
                    class="d-flex justify-center align-center flex-column"
                    id="box"
                  >
                    <v-avatar class="ma-3" size="60">
                      <v-img :src="course.logoImg"></v-img>
                    </v-avatar>

                    <div class="text-center grey--text text--darken-3">
                      <h1 class="text-body-2">
                        <u style="text-transform: capitalize">
                          {{ course.courseName }}
                        </u>
                      </h1>
                    </div>
                  </div>
                </router-link>
              </v-col>
            </v-row>

            <!-- else loading spinner  -->
            <v-row v-else justify="center">
              <v-progress-circular
                indeterminate
                color="deep-purple"
              ></v-progress-circular>
            </v-row>
          </v-card-text>

          <!-- More Courses -->
          <v-expand-transition
            v-if="
              !error &&
              courses.length > 0 &&
              otherCourses.length > 0 &&
              !loading
            "
          >
            <div v-show="showAllCourses">
              <v-divider class="mt-5"></v-divider>

              <v-card-text>
                <!-- if courses fetched -->
                <v-row justify="start" v-if="!loading">
                  <v-col md="2" v-for="(course, i) in otherCourses" :key="i">
                    <router-link
                      :to="{
                        path: `/course/${allCoursesIndex(course.courseName)}`,
                      }"
                    >
                      <div
                        class="d-flex justify-center align-center flex-column"
                        id="box"
                      >
                        <v-avatar class="ma-3" size="60">
                          <v-img :src="course.logoImg"></v-img>
                        </v-avatar>

                        <div class="text-center grey--text text--darken-3">
                          <h1 class="text-body-2">
                            <u style="text-transform: capitalize">
                              {{ course.courseName }}
                            </u>
                          </h1>
                        </div>
                      </div>
                    </router-link>
                  </v-col>
                </v-row>
              </v-card-text>
            </div>
          </v-expand-transition>

          <!-- Show All Courses Button -->
          <v-row
            justify="center"
            v-if="!error && otherCourses.length > 0 && !loading"
          >
            <v-btn icon @click="showAllCourses = !showAllCourses">
              <v-icon>{{
                showAllCourses ? "mdi-chevron-up" : "mdi-chevron-down"
              }}</v-icon>
            </v-btn>
          </v-row>

          <!-- else display fetching error -->
          <v-card-text v-if="error && !loading" class="mt-5">
            <v-alert dense text type="warning">
              {{ error }}
            </v-alert>
          </v-card-text>
        </v-card>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Courses",
  props: ["landingPage"],
  computed: {
    // returns array of only top 6 courses
    topCourses() {
      var topCourses = [];
      if (this.courses.length > 4) {
        for (let index = 0; index <= 4; index++) {
          const course = this.courses[index];
          topCourses.push(course);
        }
      } else {
        topCourses = this.courses;
      }
      return topCourses;
    },

    // returns array containing every other course except the top 6 courses
    otherCourses() {
      var otherCourses = [];
      if (this.courses.length > 5) {
        for (let index = 6; index < this.courses.length; index++) {
          const course = this.courses[index];
          otherCourses.push(course);
        }
      }
      return otherCourses;
    },
  },
  data: () => ({
    loading: true,
    error: "",
    courses: [], // get from Server
    showAllCourses: false,
  }),
  methods: {
    allCoursesIndex(courseName) {
      var index = 0;

      this.courses.some((courseObj, i) => {
        index = i;
        //array.some (breaks on truthy return value)
        return courseObj.courseName == courseName;
      });

      return index;
    },

    fetchCourses() {
      // get courses array from store
      const coursesArr = this.$store.getters.courses;

      //if not found in store
      if (coursesArr.length <= 0 || typeof coursesArr == "undefined") {
        //get folder names from server
        this.$store
          .dispatch("getMetaDoc")
          .then((res) => {
            if (res) {
              this.courses = res.courses;
            }
          })
          .catch((error) => {
            this.error = error;
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        // if found in store
        this.courses = coursesArr;
        this.loading = false;
      }
    },
  },
  mounted() {
    this.fetchCourses();
  },
};
</script>