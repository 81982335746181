<template>
  <div id="hero_header" class="d-flex justify-center align-center">
    <!-- Overlay Gradient sheet -->
    <v-sheet id="sheet" class="d-flex align-center rounded-lg">
      <v-carousel cycle hide-delimiters show-arrows-on-hover>
        <!-- Slides -->
        <v-carousel-item v-for="(slide, i) in slideData" :key="i">
          <v-row class="fill-height" align="center" justify="center" no-gutters>
            <!-- Empty Filler Column -->
            <v-col sm="12" md="1" class="px-12">
              <v-spacer></v-spacer>
            </v-col>

            <!-- Text Column -->
            <v-col cols="12" sm="12" md="5" class="pa-4 pa-md-12 text-left">
              <!-- Welcome to PlusMinus -->
              <v-chip large color="blue darken-4" class="white--text mb-6 px-5">
                Welcome to PlusMinus
              </v-chip>

              <!-- Slide Heading -->
              <h1 class="text-h2 montserrat-font mb-4 white--text">
                {{ slide.heading }}
              </h1>

              <!-- Slide Text -->
              <h3
                class="text-subtitle-1 montserrat-font pa-1 pa-md-2 white--text"
              >
                {{ slide.text }}
              </h3>

              <!-- Registration Buttons -->
              <div>
                <v-btn class="my-5 mx-2" to="/login">Log In</v-btn>
                <v-btn class="my-5 mx-2" to="/login">Register</v-btn>
              </div>
            </v-col>

            <v-col sm="12" md="5" class="px-12">
              <v-img
                class="mx-auto hidden-sm-and-down"
                style="max-height: 50vh"
                :src="require(`../../assets/${slide.image}`)"
                contain
              ></v-img>
            </v-col>
          </v-row>
        </v-carousel-item>
      </v-carousel>
    </v-sheet>
  </div>
</template>

<script>
export default {
  name: "HeroHeader",
  data: () => ({
    slideData: [
      {
        heading: "Get started for free",
        text: "Free Mock tests, Test series and Pdfs",
        image: "landing/undraw_online_resume_re_ru7s.svg",
      },
      {
        heading: "One place, all exams",
        text: "Best in class online test series platform. With Exam like interface tackle the rush of final exam before the exam.",
        image: "landing/undraw_online_test_gba7.svg",
      },
      {
        heading: "Highest quality questions",
        text: "Including a detailed explanation. Still got a doubt? Subject experts one text away.",
        image: "landing/undraw_project_team_lc5a.svg",
      },
      {
        heading: "Instant result analysis",
        text: "Check your detailed performance analysis and improve your time management and other skills.",
        image: "landing/undraw_Dark_analytics_re_2kvy.svg",
      },
    ],
  }),
};
</script>

<style>
#hero_header {
  min-height: 93.5vh;
  width: 100vw;
}

#hero_header > #sheet {
  min-height: 88vh;
  width: 90vw;

  background: #0052d4; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    #0052d4,
    #65c7f7
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #0052d4,
    #65c7f7
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
</style>