<template>
  <div id="footer">
    <v-footer color="grey lighten-3">
      <v-container>
        <!-- Tag Line -->
        <v-row class="my-1" justify="center" align="center">
          <v-col cols="12" sm="12" class="text-center text-overline">
            <div>PlusMinus</div>
            <div>Learn . Practice . Achieve</div>
          </v-col>
        </v-row>

        <!-- Features headline -->
        <v-row justify="space-between">
          <v-col cols="12" sm="12" md="4">
            <h1 class="text-h5">About Us:</h1>
            <p class="text-body-1 mt-4">
              At Plus Minus, we believe that anyone and everyone can achieve
              their goals, provided the best resources and study material. We
              all know Knowledge is of no value unless you put it into practice
              and with that thought we are on a mission to reshape the process
              of exam preparation by providing best in class Online Mock Test
              experience to evaluate preparation level. Exam before the real
              exam helps in time management, imbuing confidence and most
              importantly eliminating the weak spots.
            </p>
          </v-col>
          <v-col sm="12" md="4">
            <!-- Youtube -->
            <a
              href="https://www.youtube.com/channel/UCe6ZvPlyKSvji8xydgxzgTw"
              target="_blank"
              rel="noopener noreferrer"
            >
              <v-list-item two-line>
                <v-list-item-icon>
                  <v-icon>mdi-youtube</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Free classes on Youtube</v-list-item-title>
                  <v-list-item-subtitle> PlusMinus </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </a>

            <!-- Telegram -->
            <a
              href="https://t.me/plusminuseducation"
              target="_blank"
              rel="noopener noreferrer"
            >
              <v-list-item two-line>
                <v-list-item-icon>
                  <v-icon>mdi-send mdi-rotate-315</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Join us on Telegram</v-list-item-title>
                  <v-list-item-subtitle>
                    t.me/plusminuseducation
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </a>

            <!-- Facebook -->
            <a
              href="https://facebook.com/plusminusedu"
              target="_blank"
              rel="noopener noreferrer"
            >
              <v-list-item two-line>
                <v-list-item-icon>
                  <v-icon>mdi-thumb-up</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Follow us on Facebook</v-list-item-title>
                  <v-list-item-subtitle>
                    facebook.com/plusminusedu
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </a>

            <!-- Instagram -->
            <a
              href="https://www.instagram.com/plusminuseducation/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <v-list-item two-line>
                <v-list-item-icon>
                  <v-icon>mdi-instagram</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    >Stay updated on Instagram</v-list-item-title
                  >
                  <v-list-item-subtitle>
                    instagram.com/plusminuseducation
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </a>
          </v-col>
        </v-row>
      </v-container>

      <v-container>
        <v-row align="center" justify="center" class="text-center">
          <v-col>
            <a href="/free-pdfs" class="d-flex align-center justify-center">
              <v-icon size="20">mdi-chevron-right</v-icon> Free PDFs
            </a>
          </v-col>

          <v-col>
            <a href="/live-quiz" class="d-flex align-center justify-center">
              <v-icon size="20">mdi-chevron-right</v-icon>Live Quiz
            </a>
          </v-col>

          <v-col>
            <a href="/login" class="d-flex align-center justify-center">
              <v-icon size="20">mdi-chevron-right</v-icon>Login
            </a>
          </v-col>

          <v-col>
            <a href="/register" class="d-flex align-center justify-center">
              <v-icon size="20">mdi-chevron-right</v-icon>Register
            </a>
          </v-col>

          <v-col>
            <a href="/premium-pass" class="d-flex align-center justify-center">
              <v-icon size="20">mdi-chevron-right</v-icon>Premium Pass
            </a>
          </v-col>

          <v-col>
            <a
              href="/terms-and-conditions"
              class="d-flex align-center justify-center"
            >
              <v-icon size="20">mdi-chevron-right</v-icon> Terms and Conditions
            </a>
          </v-col>

          <v-col>
            <a
              href="/privacy-policy"
              class="d-flex align-center justify-center"
            >
              <v-icon size="20">mdi-chevron-right</v-icon> Privacy Policy
            </a>
          </v-col>
        </v-row>
      </v-container>

      <!-- Courses -->
      <v-container>
        <v-row align="center" justify="center" class="mb-6">
          <v-divider></v-divider>
        </v-row>
        <v-row align="center" justify="start"> Test Series Quick Links: </v-row>

        <!-- Loop Row -->
        <v-row align="start" justify="start" class="text-center">
          <v-col v-for="(course, i) in courses" :key="i">
            <a
              :href="`/course/${i}`"
              class="
                d-flex
                align-center
                justify-start
                text-transform-capitalize
              "
            >
              <v-icon size="20">mdi-chevron-right</v-icon>
              {{ course.courseName }}
            </a>
          </v-col>
        </v-row>
      </v-container>

      <v-container>
        <v-col class="text-center d-flex align-center justify-center" cols="12">
          PlusMinusEdu -
          {{ new Date().getFullYear() }}
          <v-icon class="ml-1">mdi-copyright</v-icon>
        </v-col>

        <v-col class="text-center d-flex align-center justify-center" cols="12">
          <v-icon class="mr-2">mdi-devices</v-icon>
          Developed by&nbsp;

          <a
            target="_blank"
            href="https://www.linkedin.com/in/mridul-kumar-7a1815178"
            color="text--primary"
          >
            Mridul Kumar
          </a>
        </v-col>
      </v-container>
    </v-footer>
  </div>
</template>

<script>
export default {
  name: "Footer",

  data: () => ({
    loading: true,
    error: "",
    courses: [], // get from Server
    showAllCourses: false,
  }),

  methods: {
    fetchCourses() {
      // get courses array from store
      const coursesArr = this.$store.getters.courses;

      //if not found in store
      if (coursesArr.length <= 0 || typeof coursesArr == "undefined") {
        //get folder names from server
        this.$store
          .dispatch("getMetaDoc")
          .then((res) => {
            if (res) {
              this.courses = res.courses;
            }
          })
          .catch((error) => {
            this.error = error;
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        // if found in store
        this.courses = coursesArr;
        this.loading = false;
      }
    },
  },
  mounted() {
    this.fetchCourses();
  },
};
</script>
