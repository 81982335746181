<template>
  <div id="features">
    <v-container>
      <!-- Features headline -->
      <v-row>
        <v-col cols="12" sm="3">
          <h1 class="text-h3 font-weight-light">Features for everyone :</h1>
        </v-col>
      </v-row>
      <v-row class="mt-n6">
        <v-col cols="12" sm="4">
          <p class="mt-3 px-1">
            We offer a very wide range of features, for everyone involved. The
            features' list is an ever-growing one, here are some of them :
          </p>
        </v-col>
      </v-row>

      <!-- Feature Cards Medium and Up -->
      <v-row class="hidden-sm-and-down">
        <!-- Highest Quality Questions -->
        <v-col cols="12" sm="12" md="4">
          <v-card dark class="py-3 mx-md-3 px-3 pb-9">
            <v-card-subtitle>
              <v-icon color="blue">mdi-check-decagram-outline mdi-36px</v-icon>
            </v-card-subtitle>
            <v-card-title class="headline">
              Highest Quality Questions
            </v-card-title>

            <v-card-text>
              Questions created by highly experienced subject experts, based on
              latest exam pattern and updated level.
            </v-card-text>
          </v-card>
        </v-col>

        <!-- Detailed solutions -->
        <v-col cols="12" sm="12" md="4" style="margin-top: -6em">
          <v-card dark class="py-3 mx-md-3 px-3 pb-9">
            <v-card-subtitle>
              <v-icon color="green"> mdi-text-box-multiple mdi-36px </v-icon>
            </v-card-subtitle>
            <v-card-title class="headline"> Detailed solutions </v-card-title>

            <v-card-text>
              To the point explanation for every solution including tips and
              methods for being time efficient.
            </v-card-text>
          </v-card>
        </v-col>

        <!-- Instant Analysis -->
        <v-col cols="12" sm="12" md="4" style="margin-top: -12em">
          <v-card dark class="py-3 mx-md-3 px-3 pb-9">
            <v-card-subtitle>
              <v-icon color="deep-purple"> mdi-chart-box mdi-36px </v-icon>
            </v-card-subtitle>
            <v-card-title class="headline"> Instant Analysis </v-card-title>

            <v-card-text>
              Metrics like score, accuracy, tank and a complete analysis to
              improve your performance.
            </v-card-text>
          </v-card>
        </v-col>

        <!-- Gov. like exam interface -->
        <v-col cols="12" sm="12" md="4">
          <v-card dark class="py-3 mx-md-3 px-3 pb-9">
            <v-card-subtitle>
              <v-icon color="orange"> mdi-monitor-dashboard mdi-36px </v-icon>
            </v-card-subtitle>
            <v-card-title class="headline">
              Gov. like exam interface
            </v-card-title>

            <v-card-text>
              We offer a user friendly design very close to the government-like
              interface to help you adjust to it beforehand.
            </v-card-text>
          </v-card>
        </v-col>

        <!-- Your Language -->
        <v-col cols="12" sm="12" md="4" style="margin-top: -6em">
          <v-card dark class="py-3 mx-md-3 px-3 pb-9">
            <v-card-subtitle>
              <v-icon color="pink">mdi-account-heart mdi-36px</v-icon>
            </v-card-subtitle>
            <v-card-title class="headline"> Your Language </v-card-title>

            <v-card-text>
              Every test including solutions and notes is available in Hindi and
              English. Easily changable during and after tests.
            </v-card-text>
          </v-card>
        </v-col>

        <!-- Live Tests -->
        <v-col cols="12" sm="12" md="4" style="margin-top: -12em">
          <v-card dark class="py-3 mx-md-3 px-3 pb-9">
            <v-card-subtitle>
              <v-icon color="blue">mdi-star mdi-36px</v-icon>
            </v-card-subtitle>
            <v-card-title class="headline"> Live Tests </v-card-title>

            <v-card-text>
              For real time exam experience, imbuing confidence and overcoming
              the time management hurdle.
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- Feature Cards Small and down -->
      <v-row class="hidden-md-and-up">
        <!-- Highest Quality Questions -->
        <v-col cols="12" sm="12" md="4">
          <v-card dark class="py-3 mx-md-3 px-3 pb-9">
            <v-card-subtitle>
              <v-icon color="blue">mdi-check-decagram-outline mdi-36px</v-icon>
            </v-card-subtitle>
            <v-card-title class="headline">
              Highest Quality Questions
            </v-card-title>

            <v-card-text>
              Questions created by highly experienced subject experts, based on
              latest exam pattern and updated level.
            </v-card-text>
          </v-card>
        </v-col>

        <!-- Detailed solutions -->
        <v-col cols="12" sm="12" md="4">
          <v-card dark class="py-3 mx-md-3 px-3 pb-9">
            <v-card-subtitle>
              <v-icon color="green"> mdi-text-box-multiple mdi-36px </v-icon>
            </v-card-subtitle>
            <v-card-title class="headline"> Detailed solutions </v-card-title>

            <v-card-text>
              To the point explanation for every solution including tips and
              methods for being time efficient.
            </v-card-text>
          </v-card>
        </v-col>

        <!-- Instant Analysis -->
        <v-col cols="12" sm="12" md="4">
          <v-card dark class="py-3 mx-md-3 px-3 pb-9">
            <v-card-subtitle>
              <v-icon color="deep-purple"> mdi-chart-box mdi-36px </v-icon>
            </v-card-subtitle>
            <v-card-title class="headline"> Instant Analysis </v-card-title>

            <v-card-text>
              Metrics like score, accuracy, tank and a complete analysis to
              improve your performance.
            </v-card-text>
          </v-card>
        </v-col>

        <!-- Gov. like exam interface -->
        <v-col cols="12" sm="12" md="4">
          <v-card dark class="py-3 mx-md-3 px-3 pb-9">
            <v-card-subtitle>
              <v-icon color="orange"> mdi-monitor-dashboard mdi-36px </v-icon>
            </v-card-subtitle>
            <v-card-title class="headline">
              Gov. like exam interface
            </v-card-title>

            <v-card-text>
              We offer a user friendly design very close to the government-like
              interface to help you adjust to it beforehand.
            </v-card-text>
          </v-card>
        </v-col>

        <!-- Your Language -->
        <v-col cols="12" sm="12" md="4">
          <v-card dark class="py-3 mx-md-3 px-3 pb-9">
            <v-card-subtitle>
              <v-icon color="pink">mdi-account-heart mdi-36px</v-icon>
            </v-card-subtitle>
            <v-card-title class="headline"> Your Language </v-card-title>

            <v-card-text>
              Every test including solutions and notes is available in Hindi and
              English. Easily changable during and after tests.
            </v-card-text>
          </v-card>
        </v-col>

        <!-- Live Tests -->
        <v-col cols="12" sm="12" md="4">
          <v-card dark class="py-3 mx-md-3 px-3 pb-9">
            <v-card-subtitle>
              <v-icon color="blue">mdi-star mdi-36px</v-icon>
            </v-card-subtitle>
            <v-card-title class="headline"> Live Tests </v-card-title>

            <v-card-text>
              For real time exam experience, imbuing confidence and overcoming
              the time management hurdle.
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Features",
  data: () => ({
    colors: ["red", "orange", "blue", "teal", "pink", "purple", "green"],
  }),
  methods: {
    getRandomColor() {
      const colors = this.colors;
      const randomColor = Math.floor(Math.random() * colors.length);
      return colors[randomColor];
    },
  },
};
</script>