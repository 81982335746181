<template>
  <!-- Guest buttons -->
  <v-list nav id="drawerButtons" class="ma-md-3">
    <v-list-item-group active-class="deep-purple--text text--accent-4">
      <v-list-item to="/live-quiz">
        <v-list-item-icon>
          <v-icon class="mr-1" size="20">mdi-youtube</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Live Quiz</v-list-item-title>
      </v-list-item>

      <v-list-item to="/free-pdfs">
        <v-list-item-icon>
          <v-icon class="mr-1" size="20">mdi-file-pdf-box</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Free PDFs</v-list-item-title>
      </v-list-item>

      <v-list-item
        v-for="(optionData, i) in options"
        :key="i"
        :to="optionData.to"
      >
        <v-list-item-icon>
          <v-icon
            class="mr-1"
            :color="optionData.color ? optionData.color : ''"
            size="20"
            >{{ optionData.icon }}</v-icon
          >
        </v-list-item-icon>
        <v-list-item-title>{{ optionData.text }}</v-list-item-title>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>
  
<script>
export default {
  name: "DrawerButtons",
  props: ["options"],
};
</script>