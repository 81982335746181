<template>
  <div id="publicNavButtons">
    <!-- Display buttons for data passed as prop -->
    <v-btn
      v-for="(optionData, i) in options"
      :key="i"
      :to="optionData.to"
      class="mr-1 hidden-sm-and-down"
      style="text-transform: capitalize"
      depressed
      :text="!isAuth"
      :icon="!!isAuth"
      :title="optionData.text"
    >
      <span v-if="!isAuth" class="d-flex align-center">
        {{ optionData.text }}
      </span>

      <span v-else class="d-flex align-center">
        <v-icon :color="optionData.color ? optionData.color : ''" size="20">
          {{ optionData.icon }}</v-icon
        >
      </span>
    </v-btn>
  </div>
</template>
  
<script>
export default {
  name: "PublicNavButtons",
  props: ["isAuth", "options"],
  methods: {
    logout() {
      this.$emit("logout");
    },
  },
};
</script>