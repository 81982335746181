import Vue from "vue";
import App from "./App.vue";
import router from "./router.js";
import store from "./store.js";
import vuetify from "./plugins/vuetify";

import VueResource from "vue-resource";
import Adsense from "vue-google-adsense/dist/Adsense.min.js";

Vue.use(VueResource);

Vue.use(require("vue-script2"));
Vue.use(Adsense);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
