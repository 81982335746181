<template>
  <div id="accomplishments">
    <!-- Overlay Gradient sheet -->
    <v-sheet
      id="sheet"
      class="fill-height d-flex align-center justify-center mt-n2 py-9"
    >
      <v-container>
        <!-- Features row -->
        <v-row align="center">
          <!-- Why PlusMinus Heading -->
          <v-col cols="12" sm="12" md="3">
            <v-list-item three-line>
              <v-list-item-content class="white--text">
                <v-list-item-title class="text-h4 mb-1 text-center">
                  Why choose<br />
                  PlusMinus ?
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>

          <!-- Quality Mock Tests -->
          <v-col cols="12" sm="12" md="3" class="white--text d-flex">
            <v-icon class="mr-3 mt-n3" color="white" size="50"
              >mdi-certificate</v-icon
            >
            <div>
              <h3 class="text-title mb-1">Quality Mock Tests</h3>
              <p>
                Based on latest exam patterns with detailed time efficient
                solutions.
              </p>
            </div>
          </v-col>

          <!-- Realtime Rank -->
          <v-col cols="12" sm="12" md="3" class="white--text d-flex">
            <v-icon class="mr-3 mt-n3" color="white" size="50"
              >mdi-trophy-award</v-icon
            >
            <div>
              <h3 class="text-title mb-1">Realtime Rank</h3>
              <p>
                Compete with thousands of students across India and view result
                in real-time.
              </p>
            </div>
          </v-col>

          <!-- Detailed Result Analysis -->
          <v-col cols="12" sm="12" md="3" class="white--text d-flex">
            <v-icon class="mr-3 mt-n3" color="white" size="50"
              >mdi-text-box-search</v-icon
            >
            <div>
              <h3 class="text-title mb-1">Detailed Result Analysis</h3>
              <p>
                Time taken, weak points, comparison with top scorer and more to
                improve.
              </p>
            </div>
          </v-col>
        </v-row>

        <!-- Accomplishments row -->
        <v-row>
          <!-- Empty filler col -->
          <v-col cols="12" sm="12" md="3"> </v-col>

          <!-- Sub Count -->
          <v-col cols="12" sm="12" md="3">
            <v-list-item three-line>
              <v-list-item-icon tile size="80" color="grey">
                <v-icon class="white--text">mdi-account-group mdi-48px</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="white--text">
                <v-list-item-title class="text-h4 mb-1">
                  {{ subCount }}+
                </v-list-item-title>
                <v-list-item-subtitle class="white--text text-subtitle-1">
                  Youtube Subscribers
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>

          <!-- Made In India -->
          <v-col cols="12" sm="12" md="3">
            <v-list-item three-line>
              <v-list-item-icon tile size="80" color="grey">
                <v-icon class="white--text">mdi-map-marker mdi-48px</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="white--text">
                <v-list-item-subtitle class="white--text text-subtitle-1">
                  Made in
                </v-list-item-subtitle>
                <v-list-item-title class="text-h4 mb-1">
                  India
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>

          <!-- Trusted By -->
          <v-col cols="12" sm="12" md="3">
            <v-list-item three-line>
              <v-list-item-icon tile size="80" color="grey">
                <v-icon class="white--text">mdi-youtube mdi-48px</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="white--text">
                <v-list-item-title class="text-h4 mb-1">
                  1.5M+
                </v-list-item-title>
                <v-list-item-subtitle class="white--text text-subtitle-1">
                  Youtube Views
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
  </div>
</template>

<script>
export default {
  name: "Accomplishments",
  data: () => ({
    subCount: 22500,
  }),
  methods: {
    fetchSubCount() {
      this.$http
        .get(
          "https://www.googleapis.com/youtube/v3/channels?part=statistics&id=UCe6ZvPlyKSvji8xydgxzgTw&key=AIzaSyCaizwYQd13ZvCjpn70ENYtJ74K13pTeak"
        )
        .then((data) => {
          if (data && "body" in data && "statistics" in data.body.items[0]) {
            this.subCount = data.body.items[0].statistics.subscriberCount;
          }
        });
    },
  },
  mounted() {
    this.fetchSubCount();
  },
};
</script>

<style>
#accomplishments #sheet {
  background: #0052d4;
  background: -webkit-linear-gradient(to right, #348ee6, #b437e2);
  background: linear-gradient(to right, #348ee6, #b437e2);
}

/* #accomplishments #sheet {
  background: #65c7f7;
} */
</style>