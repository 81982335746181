<template>
  <div class="landing">
    <HeroHeader />
    <div class="courses-offset"></div>
    <Courses :landingPage="true" />
    <div class="my-6 my-md-12"></div>
    <Features />
    <div class="my-6 my-md-12 py-1"></div>
    <Accomplishments />
    <div class="my-6 my-md-12 py-1"></div>
    <Testimonials />
    <div class="my-6 my-md-12 py-md-2"></div>
    <ContactUs />
    <div class="my-6 my-md-12 py-md-6"></div>
    <Footer />
  </div>
</template>

<script>
import HeroHeader from "@/components/Landing/HeroHeader.vue";
import Courses from "@/components/Landing/Courses.vue";
import Features from "@/components/Landing/Features.vue";
import Accomplishments from "@/components/Landing/Accomplishments.vue";
import Testimonials from "@/components/Landing/Testimonials.vue";
import ContactUs from "@/components/Landing/ContactUs.vue";
import Footer from "@/components/Landing/Footer.vue";

export default {
  name: "Landing",
  metaInfo: {
    title: "PlusMinus – Mock Tests, Test Series and Pdf Notes",
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "Most trusted exam preparation website for DSSSB, CTET, HTET and other state exams. Highest quality Free Mock tests, Test series, Notes and online classes to help you crack the exam.",
      },
      {
        vmid: "keywords",
        name: "keywords",
        content:
          "Free Mock Tests, Test Series, Online Mock tests, DSSSB, DSSSB Exams, SSC, CTET, HTET, Exam Preparations",
      },
      {
        vmid: "robots",
        name: "robots",
        content: "index, follow",
      },
    ],
  },
  components: {
    HeroHeader,
    Courses,
    Features,
    Accomplishments,
    Testimonials,
    ContactUs,
    Footer,
  },
};
</script>

<style>
.landing .courses-offset {
  margin-top: -5.5rem;
}
</style>