<template>
  <nav id="navbar">
    <v-app-bar
      v-if="currentRouteName != 'Test'"
      dense
      app
      flat
      elevate-on-scroll
      color="white"
      :class="
        currentRouteName == 'Landing'
          ? this.$vuetify.breakpoint.mdAndUp
            ? 'pt-2 pb-15 d-flex justify-center'
            : 'pt-1 d-flex justify-center'
          : 'd-flex justify-center'
      "
      clipped-right
    >
      <span style="width: 82vw" class="d-flex align-center">
        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title class="ml-sm-2 ml-md-12">
          <v-btn
            :to="auth ? '/dashboard' : '/'"
            class="text-capitalize rounded-xl mr-1"
            depressed
            text
          >
            <span class="d-flex align-center">
              <v-icon size="18" class="mr-1">mdi-home-variant</v-icon>
              <span>Plus</span>
              <span class="deep-purple--text">Minus</span>
            </span>
          </v-btn>
        </v-toolbar-title>

        <PublicNavButtons :isAuth="auth" :options="publicOptions" />

        <v-spacer></v-spacer>

        <!-- Navigation buttons top right -->
        <NavButtons
          :isAuth="auth"
          :options="auth ? authOptions : guestOptions"
          @logout="logout"
        />
      </span>
    </v-app-bar>

    <!-- Side Drawer -->
    <NavDrawer
      :isAuth="auth"
      :drawer="drawer"
      :options="{ guestOptions, authOptions }"
      @setDrawer="setDrawer"
      @logout="logout"
    />
  </nav>
</template>
  
<script>
import NavDrawer from "@/components/NavBar/NavDrawer/NavDrawer.vue";
import PublicNavButtons from "@/components/NavBar/PublicNavButtons.vue";
import NavButtons from "@/components/NavBar/NavButtons.vue";

export default {
  name: "Navbar",
  components: { NavButtons, NavDrawer, PublicNavButtons },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    auth: {
      get() {
        return this.$store.getters.auth;
      },
      set(newAuth) {
        return newAuth;
      },
    },
  },
  watch: {
    // whenever route changes, this function will run
    currentRouteName: function () {
      if (
        this.currentRouteName != "Landing" &&
        this.currentRouteName != "Test" &&
        this.currentRouteName != "LiveQuiz"
      ) {
        this.drawer = null;
      } else {
        this.drawer = false;
      }
    },
  },
  data: () => ({
    drawer: false,
    publicOptions: [
      {
        to: "/free-pdfs",
        icon: "mdi-file-pdf-box",
        text: "Free PDFs",
        color: "",
      },
      {
        to: "/live-quiz",
        icon: "mdi-youtube",
        text: "Live Quiz",
        color: "",
      },
    ],

    guestOptions: [
      {
        to: "/premium-pass",
        icon: "mdi-crown",
        text: "Premium Pass",
        color: "amber darken-3",
      },
      { to: "/login", icon: "mdi-login-variant", text: "Login" },
      { to: "/register", icon: "mdi-account-plus-outline", text: "Register" },
    ],

    authOptions: [
      { to: "/dashboard", icon: "mdi-view-compact", text: "Dashboard" },
      {
        to: "/premium-pass",
        icon: "mdi-crown",
        text: "Premium Pass",
        color: "amber darken-3",
      },
      { to: "/profile", icon: "mdi-account-circle", text: "Profile" },
    ],
  }),
  methods: {
    setDrawer(value) {
      this.drawer = value;
    },
    logout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push({ name: "Landing" });
      });
    },
  },
};
</script>

